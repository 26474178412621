import React, { useState } from "react"
import "../../styles/all.scss"
import Tabs from "../tabs/tabs-container"
import Tab from "../tabs/tab"
import TabsContent from "../tabs/tab-content"
import Team from "./team"

const Grade = ({ girlsQuery, boysQuery }) => {
  const [activeTab, setActiveTab] = useState("girls")

  return (
    <div className="has-margin-bottom-35">
      <Tabs moreClassNames="is-toggle is-primary is-centered has-margin-top-15 has-margin-bottom-15">
        <Tab
          title="girls"
          activeTab={activeTab === "girls"}
          changeActiveTab={() => {
            setActiveTab("girls")
          }}
        />
        <Tab
          title="boys"
          activeTab={activeTab === "boys"}
          changeActiveTab={() => {
            setActiveTab("boys")
          }}
        />
      </Tabs>
      <TabsContent>
        {activeTab === "girls" && <Team queryResult={girlsQuery} />}
        {activeTab === "boys" && <Team queryResult={boysQuery} />}
      </TabsContent>
    </div>
  )
}

export default Grade
