import React, { useState } from "react"
import "../../styles/all.scss"
import PlayerTable from "./player-table"
import Tabs from "../tabs/tabs-container"
import Tab from "../tabs/tab"
import TabsContent from "../tabs/tab-content"
import ResultsTable from "./results-table"
import PictureGrid from "./picture-grid"

const Team = ({ queryResult }) => {
  const [activeTab, setActiveTab] = useState("first")

  if (!queryResult || !queryResult.edges || !queryResult.edges[0]) {
    return (
      <div className="has-margin-left-20">
        Sorry, there is no information yet for this team.
      </div>
    )
  }

  const { edges } = queryResult
  let onlyOneTeam = edges[0].node.team === "N/A"

  const teamOne = edges.find(
    edge => edge.node.team === "N/A" || edge.node.team === "Red"
  ).node
  const teamTwo = onlyOneTeam
    ? null
    : edges.find(edge => edge.node.team === "White").node

  return (
    <>
      <div className="content has-margin-top-15">
        {onlyOneTeam ? (
          <>
            <div className="columns has-margin-left-10 has-margin-right-10">
              <div className="column is-6">
                <PlayerTable
                  players={teamOne.teamMembers.filter(member => !member.coach)}
                  coaches={teamOne.teamMembers.filter(member => member.coach)}
                  note={teamOne.note}
                />
              </div>
            </div>
            <div className="has-margin-left-25 has-margin-right-25">
              {/*<ResultsTable results={teamOne.results} />*/}
              {/*<PictureGrid pictures={teamOne.pictures} />*/}
            </div>
          </>
        ) : (
          <>
            <Tabs moreClassNames="is-centered has-margin-left-10 has-margin-right-10">
              <Tab
                title="Red Team"
                activeTab={activeTab === "first"}
                changeActiveTab={() => {
                  setActiveTab("first")
                }}
              />
              <Tab
                title="White Team"
                activeTab={activeTab === "second"}
                changeActiveTab={() => {
                  setActiveTab("second")
                }}
              />
            </Tabs>
            <TabsContent moreClassNames="has-margin-left-10 has-margin-right-10">
              {activeTab === "first" && (
                <>
                  <PlayerTable
                    players={teamOne.teamMembers.filter(
                      member => !member.coach
                    )}
                    coaches={teamOne.teamMembers.filter(member => member.coach)}
                    note={teamOne.note}
                  />
                  <div className="has-margin-left-5 has-margin-right-5">
                    {/*<ResultsTable results={teamOne.results} />*/}
                    {/*<PictureGrid*/}
                    {/*  pictures={teamOne.pictures}*/}
                    {/*  className="has-margin-top-20"*/}
                    {/*/>*/}
                  </div>
                </>
              )}
              {activeTab === "second" && (
                <>
                  <PlayerTable
                    players={teamTwo.teamMembers.filter(
                      member => !member.coach
                    )}
                    coaches={teamTwo.teamMembers.filter(member => member.coach)}
                    note={teamTwo.note}
                  />
                  <div className="has-margin-left-5 has-margin-right-5">
                    {/*<ResultsTable results={teamTwo.results} />*/}
                    {/*<PictureGrid pictures={teamTwo.pictures} />*/}
                  </div>
                </>
              )}
            </TabsContent>
          </>
        )}
      </div>
    </>
  )
}
export default Team
