import React, { useState } from "react"
import "../styles/all.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroBar from "../components/hero-bar"
import Tabs from "../components/tabs/tabs-container"
import Tab from "../components/tabs/tab"
import TabsContent from "../components/tabs/tab-content"
import Fourth from "../components/teams/fourth"
import Fifth from "../components/teams/fifth"
import Sixth from "../components/teams/sixth"
import Seventh from "../components/teams/seventh"
import Eighth from "../components/teams/eighth"

const TeamsPage = () => {
  const [activeTab, setActiveTab] = useState("fourth")
  return (
    <Layout>
      <SEO title="Teams" />
      <HeroBar subtitle="Team Information" />
      <div className="has-background-white-ter has-margin-top-15">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <div className="has-margin-top-15 has-margin-15-mobile">
                <div className="tile">
                  <h1 className="title has-padding-bottom-5 why-cav">Teams</h1>
                </div>
                {/*<div className="tile">*/}
                {/*  <h3 className="subtitle">*/}
                {/*    */}
                {/*  </h3>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content">
            <Tabs moreClassNames="is-boxed has-margin-top-20 has-margin-bottom-10 has-margin-left-5-mobile has-margin-right-5-mobile">
              <Tab
                title="4th"
                activeTab={activeTab === "fourth"}
                changeActiveTab={() => {
                  setActiveTab("fourth")
                }}
              />
              <Tab
                title="5th"
                activeTab={activeTab === "fifth"}
                changeActiveTab={() => {
                  setActiveTab("fifth")
                }}
              />
              <Tab
                title="6th"
                activeTab={activeTab === "sixth"}
                changeActiveTab={() => {
                  setActiveTab("sixth")
                }}
              />
              <Tab
                title="7th"
                activeTab={activeTab === "seventh"}
                changeActiveTab={() => {
                  setActiveTab("seventh")
                }}
              />
              <Tab
                title="8th"
                activeTab={activeTab === "eighth"}
                changeActiveTab={() => {
                  setActiveTab("eighth")
                }}
              />
            </Tabs>
            <TabsContent>
              {activeTab === "fourth" && <Fourth />}
              {activeTab === "fifth" && <Fifth />}
              {activeTab === "sixth" && <Sixth />}
              {activeTab === "seventh" && <Seventh />}
              {activeTab === "eighth" && <Eighth />}
            </TabsContent>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TeamsPage
