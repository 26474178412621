import React from "react"
import "../../styles/all.scss"
import { graphql, useStaticQuery } from "gatsby"
import Grade from "./grade"

const Seventh = () => {
  const data = useStaticQuery(graphql`
    {
      GirlsQuery: allContentfulTeam(filter: { grade: { eq: "7th girls" } }) {
        edges {
          node {
            grade
            team
            teamMembers {
              name
              coach
            }
            pictures {
              fluid(maxWidth: 800, maxHeight: 800) {
                src
                srcSet
                aspectRatio
                sizes
              }
              contentful_id
            }
            results {
              name
              record
              place
            }
            note
          }
        }
      }
      BoysQuery: allContentfulTeam(filter: { grade: { eq: "7th boys" } }) {
        edges {
          node {
            grade
            team
            teamMembers {
              name
              coach
            }
            pictures {
              fluid(maxWidth: 800, maxHeight: 800) {
                src
                srcSet
                aspectRatio
                sizes
              }
              contentful_id
            }
            results {
              name
              record
              place
            }
            note
          }
        }
      }
    }
  `)

  return <Grade girlsQuery={data.GirlsQuery} boysQuery={data.BoysQuery} />
}

export default Seventh
