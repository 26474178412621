import React from "react"
import { getCoaches } from "../../utils"

const PlayerTable = ({ team = "", players = [], coaches = [], note = "" }) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th className={team ? "" : "is-borderless"}>{team}</th>
          </tr>
        </thead>
        <tbody>
          {players.map(player => {
            return (
              <tr key={player.name}>
                <td>{player.name}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div>
        <strong>Coaches: </strong>
        {getCoaches(coaches)}
      </div>
      <div className="has-margin-top-20">{note}</div>
    </>
  )
}

export default PlayerTable
